
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/visualmonitor";
@Options({
  name: "Visualmonitor",
})
export default class extends Vue {
  refreshing = false;
  finished = false;
  loading = false;
  monitorList = [];
  runningList = [];
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  async mounted() {
    api.startStreams(this.$store.state.project.projectinfo.projectId);
  }
  onLoad() {
    setTimeout(async () => {
      if (this.refreshing) {
        this.monitorList = [];
        this.refreshing = false;
      }
      const result: any = await api.runningState(
        this.$store.state.project.projectinfo.projectId
      );
      if (result.code !== 0) {
        return this.$toast.fail(result.msg);
      }
      this.runningList = result.data;
      const res: any = await api.cameraList(
        this.$store.state.project.projectinfo.projectId
      );
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.monitorList = res.data;
      this.loading = false;
      this.finished = true;
    }, 1000);
  }
}
