import request from "@/utils/request";

export function cameraList(projectId: string) {
  return request({
    url: "/project/cameraDevice/cameraList/" + projectId,
    method: "get",
  });
}

export function runningState(projectId: string) {
  return request({
    url: "/device-server/hikvision/runningState/" + projectId,
    method: "get",
  });
}

export function getVsURL(data: any) {
  return request({
    url: "/device-server/video/getVsURL",
    method: "get",
    params: data,
  });
}

export function ptz(data: any) {
  return request({
    url: "/device-server/video/ptz",
    method: "get",
    params: data,
  });
}

// 提前启动视频拉流
export function startStreams(projectId: any) {
  return request({
    url: "/device-server/gb28181/startStreams",
    method: "get",
    params: { projectId: projectId },
  });
}
